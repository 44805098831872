<template>
  <verify-order @completed="completed()" />
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'VerifyOrderPage',
  components: {
    VerifyOrder: () => import('@/components/VerifyOrder/VerifyOrder'),
  },
  data() {
    return {
      params: {},
      stageId: null,
    }
  },
  computed: {
    ...mapGetters('invoices',
      { findInvoice: 'find' }
    ),
    ...mapGetters('verifyOrder', [
      'orderRequiringVerification',
      'orderItemsRequiringAttentionCount',
      'company',
    ]),
    routedHereFromForeignQualificationsStep() {
      return this.params?.from === 'foreignQualification'
    },
  },
  async mounted() {
    this.params = this.$route?.params
    this.slideId = this.params?.slideId

    await this.fetchMostRecentDomainAndEmail(this.company.id)
  },
  methods: {
    ...mapActions('invoices', {
      loadInvoice: 'loadById',
    }),
    ...mapActions('verifyOrder', [
      'loadLatestOrderRequiringVerification',
    ]),
    ...mapActions('domains', [
      'fetchMostRecentDomainAndEmail',
    ]),
    async completed(invoiceId) {
      // Route to PostPaymentIRA if invoice has ORA
      if (invoiceId) {
        await this.loadInvoice({ id: invoiceId, params: { include_details: true } })
        const invoice = this.findInvoice(invoiceId)

        if (invoice && invoice.order?.order_items.some(oi => oi.status === 'awaiting-client-input')) {
          this.$router.push({
            name: 'checkout-required-info',
            params: {
              companyId: this.company?.id,
              invoiceId: invoiceId,
            },
          }).catch(() => {})
        }
      } else if (this.routedHereFromForeignQualificationsStep && this.slideId) {
        await this.routeToStageline()
      } else {
        const name = this.orderItemsRequiringAttentionCount > 0
          ? 'orderItemsRequiringAttention'
          : 'pendingFilings'

        this.$router.push({ name }).catch(() => {})
      }
    },
    async routeToStageline() {
      await this.$router.push({ name: 'stageline-v2-start', params: {
          companyId: this.company.id,
          slideId: this.slideId,
        },
      })
    },
  },
}
</script>
