var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("verify-order", {
    on: {
      completed: function ($event) {
        return _vm.completed()
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }